/** @jsx jsx */
import {jsx} from 'theme-ui'
import React from 'react' // eslint-disable-line

import ProgrammingLandingContent from '../components/programming-landing-content'

const ProgrammingLadnging = () => {
  return (
    <ProgrammingLandingContent />
  )
}

export default ProgrammingLadnging
